.popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
}

.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 50px rgba(150, 150, 150, 1);
    z-index: 101;
    color: white;
    background: #1E2C35;
    border: 8.31px solid #969696;
    min-width: 50%;
    text-align: center;
    opacity: 0.93;
}

.popup-content {
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    font-size: 1.3em;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    color: #ff0037;
    font-weight: bolder;
}

.popup-body {
    margin: 20px;
}

@media (max-width: 768px) {
    .popup-container{
        min-width: 75%;
    }
    .popup-content {
        font-size: 1.2rem;
    }
}

.text-yellow {
    color: #ffec00;
}

.text-red {
    color: #ff0037;
}

.text-blue {
    color: #00a4ff;
}

.text-green {
    color: #00ffb6;
}

.text-lime {
    color: #a8ff00;
}

.text-orange {
    color: #ff7100
}