@font-face {
    font-family: 'ChildChat';
    src: url('/public/fonts/ChildChat-ax6lE.ttf') format('truetype'),
        url('/public/fonts/ChildChat-Wy63Y.otf') format('opentype');
}

.interface-svg {
    font-family: 'ChildChat', sans-serif;
    height: 75vh;
    width: auto;
    /*margin: 30px;*/
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 7px;
    /*padding: 7px;*/
    z-index: 100000;
}

.interface-container {
    font-family: 'ChildChat', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    overflow: hidden;
    /*Gaussian blur*/
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); 
}
