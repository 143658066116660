@font-face {
    font-family: 'ChildChat';
    src: url('/public/fonts/ChildChat-ax6lE.ttf') format('truetype'),
        url('/public/fonts/ChildChat-Wy63Y.otf') format('opentype');
}

html, body {
    font-family: 'ChildChat', sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.layout-container {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.background-svg {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.content {
    position: relative;
    z-index: 2;
}

@media (max-width: 768px) {
    .layout-container {
        overflow-x: hidden;
    }

    .background-svg {
        width: 240%;
        height: auto;
        margin-left: -70%;
    }
}
